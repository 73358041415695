<template>
  <vx-card title="">
    <vs-row class="core-skill-header">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="12" class="mb-lg-0 mb-3">
        <div class="vx-col w-full">
          <label class="text-sm opacity-75"><h3>Rating</h3></label>
          <ul class="demo-alignment">
            <li>
              <vs-radio vs-name="rating" v-model="rating" vs-value="1" @change="onChangeRating($event)">
                1
              </vs-radio>
            </li>
            <li>
              <vs-radio vs-name="rating" v-model="rating" vs-value="2" @change="onChangeRating($event)">
                2
              </vs-radio>
            </li>
            <li>
              <vs-radio vs-name="rating" v-model="rating" vs-value="3" @change="onChangeRating($event)">
                3
              </vs-radio>
            </li>
            <li>
              <vs-radio vs-name="rating" v-model="rating" vs-value="4" @change="onChangeRating($event)">
                4
              </vs-radio>
            </li>
            <li>
              <vs-radio vs-name="rating" v-model="rating" vs-value="5" @change="onChangeRating($event)">
                5
              </vs-radio>
            </li>
          </ul>
        </div>
      </vs-col>
    </vs-row>

    <div class="op-block mt-5">
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="8">
          <p class="h4">Resources</p>
        </vs-col>
        <vs-divider/>
      </vs-row>
      <template v-if="fastCheckDevelopments.length > 0">
        <vs-row v-if="fastCheckDevelopments.length > 0" :key="index"
                v-for="(item,index) in fastCheckDevelopments">
          <vs-col vs-justify="center" vs-align="center" vs-w="9">
            <p class="h4"><a :href="item.document" target="_blank">{{item.title}}</a></p>
            <p>{{item.description}}</p>
          </vs-col>
          <vs-divider/>
        </vs-row>
      </template>
      <template v-else>
        <vs-row>
          <vs-col vs-justify="center" vs-align="center" vs-w="9">
            <p>Sorry no data found.</p>
          </vs-col>
          <vs-divider/>
        </vs-row>
      </template>
    </div>
  </vx-card>

</template>

<script>
  import vSelect from 'vue-select'
  import {mapActions} from "vuex";
  import _ from "lodash";

  export default {
    data() {
      return {
        rating: '',
        fastCheckId: '',
        fastChecks: {},

        fastCheckOptions: [],
        fastCheckFilter: {},

        fastCheckDevelopments: {},
        fastCheckDevelopmentID: null,

        // Data Sidebar
        addNewDataSidebar: false,
        sidebarData: {},
        searchQuery: "",
        currentResource: {}
      }
    },
    watch: {
      fastCheckFilter(obj) {
        this.getFastCheckDevelopments();
      },
    },
    methods: {
      ...mapActions("coreSkill", [
        "getCoreSkillDevelopment",
        "fetchCoreSkills",
        "logProfessionalDevelopment"

      ]),
      ...mapActions("fastCheck", [
        "getCenterAdminFastChecksResources",
        "fetchCenterAdminFastChecks",
        "fetchCenterAdminFastChecksDetails",
        "setRating"
      ]),
      onChangeRating(event) {
        this.setRating(this.rating)
        this.getFastCheckDevelopment();
      },

      getFastCheckDevelopment() {
        let filter = {
          fastCheckId: this.fastCheckId,
          rating: parseInt(this.rating),
        };
        let info = {
          data: filter,
          search: this.searchQuery
        };
        this.$vs.loading()
        this.getCenterAdminFastChecksResources(info)
          .then((res) => {
            this.$vs.loading.close()
            this.fastCheckDevelopments = res.data.data.map((item => {
              return item.resources
            }));
          })
          .catch(err => {
            this.$vs.loading.close()
          })
      }
    },
    components: {
      vSelect
    },
    created() {
      this.rating = this.$route.params.rating;
      this.fastCheckId = this.$route.params.fastCheckId;
      this.getFastCheckDevelopment();

    /*  const log = {
        fastCheckId : this.$route.params.fastCheckId,
        rating : this.$route.params.rating,
      }
      this.logProfessionalDevelopment(log)
        .then((res) => {
          this.$vs.loading.close()
        })
        .catch(err => {
          this.$vs.loading.close()
        })*/


    },

  }
</script>
